<template>
  <div size="A4" class="p-3 ">
    <div
      class="container relative"
      style="border: 3px solid black;width: 200mm;"
    >
      <div class="container text-center">
        <h1>PROPOSTA DE ACORDO</h1>
        <h1>CARNÊ IMPECÁVEL</h1>
      </div>
      <div class="container text-center">
        <img src="impecavel.jpg" width="120" />
      </div>
      <div class="container pt-4">
        <p>
          <b>CLIENTE:&nbsp;&nbsp;&nbsp;&nbsp;</b> {{ nome }}<br />
          <b
            >CPF:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b
          >
          {{ cpf }}<br />
          <b>DATA DA PROPOSTA:&nbsp;&nbsp;</b> {{ data_proposta | dataF }}<br />
          <b>VALIDADE DA PROPOSTA:&nbsp;&nbsp;</b> {{ validade_proposta | dataF
          }}<br />
          <b>VENCIMENTO ENTRADA:&nbsp;&nbsp;</b> {{ vencimento | dataF }}<br />
          <b>VALOR {{ ent_str }}:&nbsp;&nbsp;</b> R&#36;&nbsp;{{
            valor | dinheiro
          }}<br />
        </p>
      </div>

      <br />
      <div class="container ">
        <h4>PAGUE APENAS EM NOSSOS PONTOS DE ATENDIMENTO</h4>
      </div>
      <p class="text-left">
        1) Vá direto ao caixa de uma de nossas lojas, munido de documento de
        identidade com foto;<br /><br />
        2) Pague o valor exato da entrada até a data de seu vencimento. Após a
        data de validade da proposta, será necessário uma nova renegociação.
        <br /><br />
        Em caso de dúvidas, ligue: <b>(21) 2206-4877</b> ou Whatsapp
        <a target="_blank" href="https://cutt.ly/2dqnBXJ"
          >https://cutt.ly/2dqnBXJ</a
        >
        ou e-mail :<b
          ><a href="mailto:boleto@easboleto.com.br">
            boleto@easboleto.com.br</a
          ></b
        >
      </p>
    </div>
  </div>
</template>

<script>
//import utilidade from "../../conf/utilitario";
//import { api } from "@/conf/api";
export default {
  name: "Impecavel2",
  data() {
    return {
      cpf: "",
      nome: "",
      data_proposta: "",
      validade_proposta: "",
      vencimento: "",
      ent_str: "",
      valor: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: true });
    this.pegarBoleto();
  },
  methods: {
    async pegarBoleto() {
      try {
        let boleto = this.$store.state.boleto.data;
        console.log(boleto);
        this.cpf = boleto.cpf;
        this.nome = boleto.nome;
        this.data_proposta = boleto.data_proposta;
        this.validade_proposta = boleto.validade_proposta;
        this.vencimento = boleto.vencimento;
        this.ent_str = boleto.frase;
        this.valor = boleto.valor;
        /*
        let divida = this.$store.state.divida_atual;

        let negoc = this.$store.state.negoc_atual;

        this.cpf = divida.cpcpfcgc;
        this.nome = divida.nome;
        this.data_proposta = utilidade.datahoje();
        this.validade_proposta = this.$store.state.venc_atual;
        this.vencimento = this.$store.state.venc_atual;
        let frase = "A VISTA ";
        if (negoc.parcelas > 0) {
          frase = "DA ENTRADA ";
        }
        this.ent_str = frase;
        this.valor = negoc.valor;

        let credor = divida.credor2;
        let cpf = divida.cpcpfcgc;
        let contrato = divida.contrato;
        let cgc = divida.cpcgc;
        let boletoObj = {
          cpf: this.cpf,
          nome: this.nome,
          data_proposta: this.data_proposta,
          validade_proposta: this.validade_proposta,
          vencimento: this.vencimento,
          frase: frase,
          valor: this.valor,
        };
        let nome = divida.nome;
        let email = this.$store.state.emailCli;

        let valor = this.valor;
        let valor_entrada = valor;
        let valor_parcela = this.$store.state.negoc_atual.valorP;
        let venc_o = this.$store.state.venc_atual;
        let venc =
          venc_o.substr(8, 2) +
          "/" +
          venc_o.substr(5, 2) +
          "/" +
          venc_o.substr(0, 4);
        let fatura = "";
        let remessa = this.$store.state.divida_atual.cpdatrem;
        let parcelas_faltam = this.$store.state.parcelas_faltam;
        let codBarras = "";
        let L_dig = "";

        let dados = {
          credor,
          cgc,
          cpf,
          contrato,
          boletoObj,
          nome,
          email,
          valor,
          valor_entrada,
          valor_parcela,
          venc,
          fatura,
          remessa,
          parcelas_faltam,
          codBarras,
          L_dig,
        };
        // console.log(dados);
        await api.post(`logs/gravarboleto`, dados);

        */
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
body {
  background: rgb(204, 204, 204);
}
page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
page[size="A4"] {
  width: 21cm;
  height: 29.7cm;
}
page[size="A4"][layout="landscape"] {
  width: 29.7cm;
  height: 21cm;
}
page[size="A3"] {
  width: 29.7cm;
  height: 42cm;
}
page[size="A3"][layout="landscape"] {
  width: 42cm;
  height: 29.7cm;
}
page[size="A5"] {
  width: 14.8cm;
  height: 21cm;
}
page[size="A5"][layout="landscape"] {
  width: 21cm;
  height: 14.8cm;
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 65px;
  right: 0;
  width: 150px;
  height: 200px;
}
table,
th,
td {
  border: 3px solid black;
  padding: 0px 8px;
}
th,
td {
  text-align: center;
}
</style>
